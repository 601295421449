import React from 'react';
import { useTranslation } from 'react-i18next';

export default function HomePage() {
    const { t } = useTranslation();

    return (
        <>
            <h1>{t('Homepage')}</h1>
            <p>{t('hello you')}</p>
        </>
    );
}
