/*eslint @typescript-eslint/no-use-before-define: ["off", { "allowNamedExports": true }]*/

import React from 'react';
import { Navigate } from 'react-router-dom';

import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import InventoryIcon from '@mui/icons-material/Inventory';
import SettingsSystemDaydreamIcon from '@mui/icons-material/SettingsSystemDaydream';

import HomePage from 'features/home/HomePage';
import Demo2Page from 'features/demo2/Demo2Page';
import TaggingPage from 'features/tagging/TaggingPage';

import SalesPage from 'features/sales/SalesPage';
import CreateTenderPage from 'features/tenders/CreateTenderPage';
import NotFoundPage from 'features/404/NotFoundPage';

import { PvSolVmPage } from 'features/pv-sol-vm/PvSolVmPage';

import EmptyLayout from 'layouts/EmptyLayout';
import AppLayout from 'layouts/MainLayout';
import GlobalTaggerPage from 'features/global-tagger/GlobalTaggerPage';
import OnsiteListPage from 'features/onsite/OnsiteListPage';
import OnsiteTenderFormPage from 'features/onsite/OnsiteTenderFormPage';
import { ListProjectsPage } from 'features/projects/ListProjectsPage';
import { CreateEditProjectPage } from 'features/projects/CreateEditProjectPage';
import PVCalculatorPage from 'features/pv-calculator/PVCalculatorPage';

export enum PAGETYPE {
    MAIN,
    FOOTER,
}

export const routesConfig = [
    {
        element: <AppLayout />,
        children: [
            {
                id: 'homepage',
                path: '/',
                handle: {
                    title: 'Homepage',
                    type: PAGETYPE.MAIN,
                    icon: <HomeRoundedIcon />,
                },
                children: [
                    {
                        id: 'homepage_index',
                        index: true,
                        element: <HomePage />,
                    },

                    {
                        id: 'dev',
                        path: '/dev',
                        handle: {
                            title: 'Development',
                            type: PAGETYPE.MAIN,
                        },
                        children: [
                            {
                                id: 'global-tagger',
                                path: '/dev/global-tagger',
                                element: <GlobalTaggerPage />,
                                handle: {
                                    title: 'Global Tagger',
                                },
                            },
                            {
                                id: 'pv-calculator',
                                path: '/dev/pv-calculator',
                                element: <PVCalculatorPage />,
                                handle: {
                                    title: 'PV Calculator',
                                },
                            },
                            {
                                id: 'onsite',
                                path: '/dev/onsite',
                                element: <OnsiteListPage />,
                                handle: {
                                    title: 'Onsite',
                                },
                            },
                            {
                                id: 'onsite_details',
                                path: '/dev/onsite/:reference/tender-form',
                                element: <OnsiteTenderFormPage />,
                            },

                            {
                                id: 'projects',
                                path: '/dev/projects',
                                element: <ListProjectsPage />,
                                handle: {
                                    title: 'Projects',
                                },
                            },
                            {
                                id: 'create',
                                path: '/dev/projects/create',
                                element: <CreateEditProjectPage />,
                            },
                            {
                                id: 'edit',
                                path: '/dev/projects/:projectId',
                                element: <CreateEditProjectPage />,
                            },
                        ],
                    },
                    {
                        id: 'demo',
                        path: '/demos',
                        handle: {
                            title: 'Production',
                            type: PAGETYPE.MAIN,
                        },
                        children: [
                            {
                                // THIS INDEX PAGE IS AN EXAMPLE FOR A REDIRECT
                                id: 'demos_index',
                                index: true,
                                element: <Navigate to="/" />,
                            },

                            {
                                id: 'tagger',
                                path: '/demos/tagger',
                                element: <TaggingPage />,
                                handle: {
                                    title: 'Tagger',
                                    //    type: PAGETYPE.MAIN,
                                    //  icon: <AddBusinessIcon />,
                                },
                            },

                            {
                                id: 'demo2',
                                path: '/demos/v2',
                                element: <Demo2Page />,
                                handle: {
                                    title: 'Sales Print',
                                },
                            },
                        ],
                    },
                    // {
                    //     id: "projects",
                    //     handle: {
                    //         title: "Projects",
                    //         type: PAGETYPE.MAIN,
                    //         icon: <AccountTree />
                    //     },
                    //     children: [
                    //         {
                    //             id: "list",
                    //             path: "/projects",
                    //             element: <ListProjectsPage />,
                    //             handle: {
                    //                 title: "All Projects"
                    //             }
                    //         },
                    //         {
                    //             id: "create",
                    //             path: "/projects/create",
                    //             element: <CreateEditProjectPage />,
                    //             handle: {
                    //                 title: "New Project",
                    //             }
                    //         },
                    //         {
                    //             id: "edit",
                    //             path: "/projects/:projectId",
                    //             element: <CreateEditProjectPage />,
                    //         },
                    //         {
                    //             path: "/installationsvorhaben",
                    //             element: <ListInstallationsVorhabenPage />,
                    //         },
                    //         {
                    //             path: "/installationsvorhaben/create",
                    //             element: <CreateEditInstallationsVorhabenPage />,
                    //         },
                    //         {
                    //             path: "/installationsvorhaben/:id",
                    //             element: <InstallationsVorhabenDetailsPage />,
                    //         }
                    //     ]
                    // },
                    {
                        id: 'create-tender',
                        path: '/create-tender',
                        element: <CreateTenderPage />,
                        handle: {
                            title: 'Create Bill of Quantities',
                            type: PAGETYPE.MAIN,
                            icon: <InventoryIcon />,
                        },
                    },
                    // {
                    //     id: "objects", path: "/objects", element: <ObjectsPage />,
                    //     handle: {
                    //         title: "Properties",
                    //         type: PAGETYPE.MAIN,
                    //         icon: <DashboardRoundedIcon />,
                    //     },
                    // },
                    {
                        id: 'pv-sol-vm',
                        path: '/pv-sol-vm',
                        element: <PvSolVmPage />,
                        handle: {
                            title: 'PV*SOL VM',
                            type: PAGETYPE.MAIN,
                            icon: <SettingsSystemDaydreamIcon />,
                        },
                    },
                ],
            },

            // footer
            // {
            //     id: "settings", path: "/settings/:settingsSubPage?", element: <SettingsPage />,
            //     handle: {
            //         defaultPath: "/settings/account",
            //         title: "Settings",
            //         type: PAGETYPE.FOOTER,
            //         icon: <SettingsRoundedIcon />,
            //     },
            // },
            // {
            //     id: "support", path: "/support", element: <SupportPage />,
            //     handle: {
            //         title: "Support",
            //         type: PAGETYPE.FOOTER,
            //         icon: <SupportRoundedIcon />,   // <QuestionAnswerRoundedIcon />
            //     },
            // },
        ],
    },

    {
        element: <EmptyLayout />,
        children: [
            {
                id: 'sales',
                path: '/demos/sales',
                element: <SalesPage />,
                handle: {
                    title: 'Demo - Sales Print',
                },
            },

            {
                id: '404',
                path: '*',
                element: <NotFoundPage />,
                handle: {
                    title: '404 - Page not found',
                },
            },
        ],
    },
];
