import React from 'react';
import { FormControl, FormLabel, Typography, Input, Select, Option } from '@mui/joy';
import { formFieldBuilder, formFile, handleSelect } from '../../common/formikUtils';
import MultiImageInput from './components/MultImageInput';
import { useTranslation } from 'react-i18next';

export default function FragmentElektrischerAnschluss({ formik }: any) {
    const formField = (name: string) => formFieldBuilder(formik, name);

    const { t } = useTranslation();

    return (
        <>
            <Typography level="h3">Elektrischer Anschluss</Typography>
            <FormControl required>
                <FormLabel>Wie ist der Zustand der E-Anlage/ZS</FormLabel>
                <Select
                    sx={{ width: '20rem' }}
                    value={formik.values.roof_cladding}
                    onChange={handleSelect(formik, 'ZUSTAND_E_ANLAGE')}
                >
                    <Option key={0} value="good">
                        Gut (PV-Anlage kann daran installiert werden)
                    </Option>
                    <Option key={1} value="unclear_single_family">
                        Unklar (Standard Einfamilienhaus ZS)
                    </Option>
                    <Option key={2} value="replace_single_family">
                        Muss erneuert werden (Standard Einfamilienhaus ZS)
                    </Option>
                    <Option key={3} value="unclear_large_nshv">
                        Unklar (große NSHV wie in Schulen)
                    </Option>
                </Select>
            </FormControl>

            <FormControl required>
                <FormLabel>Aufnahme Zählerschrank</FormLabel>
                <Input type="file" {...formFile(formik, 'AUFNAHME_ZAEHLERSCHRANK')} />
            </FormControl>

            <FormControl>
                <FormLabel>Aufnahme Zähler</FormLabel>
                <MultiImageInput name="AUFNAHME_ZAEHLER" formik={formik} />
            </FormControl>

            <FormControl>
                <FormLabel>Aufnahme Zähleraufhängung</FormLabel>
                <Input type="file" {...formFile(formik, 'AUFNAHME_ZAEHLERAUFHAENGUNG')} />
            </FormControl>

            <FormControl>
                <FormLabel>Typenschild Zähler</FormLabel>
                <Input type="file" {...formFile(formik, 'AUFNAHME_TYP_ZAEHLERSCHRANK')} />
            </FormControl>

            <Typography level="h3">Installationszonen</Typography>
            <FormControl required>
                <FormLabel>Installationszone Zählerschrank</FormLabel>
                <Input type="text" placeholder="Installationszone Zählerschrank" {...formField('IZ_ZAEHLERSCHRANK')} />
            </FormControl>

            <FormControl>
                <FormLabel>Aufnahme Installationszone Zählerschrank</FormLabel>
                <Input type="file" {...formFile(formik, 'AUFNAHME_IZ_ZAEHLERSCHRANK')} />
            </FormControl>

            <FormControl required>
                <FormLabel>Installationszone Netzwerkanschluss</FormLabel>
                <Input
                    type="text"
                    placeholder="Installationszone Netzwerkanschluss"
                    {...formField('IZ_NETZWERKANSCHLUSS')}
                />
            </FormControl>

            <FormControl>
                <FormLabel>Aufnahme Installationszone Netzwerkanschluss</FormLabel>
                <Input type="file" {...formFile(formik, 'AUFNAHME_IZ_NETZWERKANSCHLUSS')} />
            </FormControl>

            <FormControl required>
                <FormLabel>Installationszone Unterverteilung</FormLabel>
                <Input
                    type="text"
                    placeholder="Installationszone Unterverteilung"
                    {...formField('IZ_UNTERVERTEILUNG')}
                />
            </FormControl>

            <FormControl>
                <FormLabel>Aufnahme Installationszone Unterverteilung</FormLabel>
                <Input type="file" {...formFile(formik, 'AUFNAHME_IZ_UNTERVERTEILUNG')} />
            </FormControl>

            <FormControl required>
                <FormLabel>Installationszone Systemkomponenten</FormLabel>
                <Input
                    type="text"
                    placeholder="Installationszone Systemkomponenten"
                    {...formField('IZ_SYSTEMKOMPONENTEN')}
                />
            </FormControl>

            <FormControl>
                <FormLabel>Installationszone der Systemkomponenten (Wechselrichter und Energiespeicher)</FormLabel>
                <Input type="file" {...formFile(formik, 'AUFNAHME_IZ_WECHSELRICHTER_AND_SPEICHER')} />
            </FormControl>

            <FormControl>
                <FormLabel>{t('Relative Position of the Rooms to each other')}</FormLabel>
                <Input type="file" {...formFile(formik, 'RELATIVE_POSITION_OF_THE_ROOMS_TO_EACH_OTHER')} />
            </FormControl>

            <FormControl required>
                <FormLabel>Installationszone Feuerwehrschalter</FormLabel>
                <Input type="text" placeholder="Installationszone Feuerwehrschalter" {...formField('IZ_FEUERWEHR')} />
            </FormControl>
        </>
    );
}
