import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { OnsiteDataPoint } from './onsite.dto';
import { loadOnsiteData } from './onsite.actions';
import { connect } from 'react-redux';
import { CircularProgress } from '@mui/joy';

interface Properties {
    formData: { [reference: string]: OnsiteDataPoint };
    isLoading: boolean;
    loadOnsiteData: (reference: string) => void;
}

function OnsiteTenderFormPage(props: Properties) {
    const { formData, isLoading, loadOnsiteData } = props;

    const { reference } = useParams();

    const [onsiteData, setOnsiteData] = useState<{
        [reference: string]: OnsiteDataPoint;
    }>();

    useEffect(() => {
        if (reference) {
            loadOnsiteData(reference);
        }
    }, [reference]);

    useEffect(() => {
        if (formData) {
            setOnsiteData(formData);
        }
    }, [formData]);

    return (
        <Fragment>
            {isLoading ? (
                <CircularProgress />
            ) : (
                <>
                    <h1>Tender Form for Onsite {reference}</h1>
                    <pre>{JSON.stringify(onsiteData, null, 2)}</pre>
                </>
            )}
        </Fragment>
    );
}

const mapStateToProps = function (state: any) {
    return {
        formData: state.onsite.formData,
        isLoading: state.onsite.isLoading,
    };
};

const mapDispatchToProps = function (dispatch: any) {
    return {
        loadOnsiteData: (reference: string) => dispatch(loadOnsiteData(reference)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OnsiteTenderFormPage);
