/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { resizeImage } from 'common/resizeImage';

export function handleSelect(formik: any, name: string) {
    return (_: any, value?: any) => {
        formik.setFieldValue(name, value ?? '');
    };
}

export function formCheckbox(formik: any, name: string) {
    return {
        checked: formik.values[name],
        onChange: (event: any) => formik.setFieldValue(name, event.target.checked),
    };
}

export function formFile(formik: any, name: string) {
    return {
        onChange: (event: any) => {
            const fileReader = new FileReader();
            const file = (event.target as HTMLInputElement).files![0];
            if (!file) {
                formik.setFieldValue(name, '');
                return;
            }

            fileReader.readAsDataURL(file);
            fileReader.onload = async () => {
                if (fileReader.readyState === fileReader.DONE) {
                    const resizedImageDataUrl = await resizeImage(fileReader.result as string, 570);
                    URL.revokeObjectURL(fileReader.result as string);

                    formik.setFieldValue(name, resizedImageDataUrl);
                }
            };
        },
    };
}

export function formFieldBuilder(formik: any, name: string) {
    return {
        name,
        value: (formik.values as any)[name],
        error: (formik.touched as any)[name] && (formik.errors as any)[name],
        onChange: formik.handleChange,
        onBlur: formik.handleBlur,
    };
}
