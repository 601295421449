/* eslint @typescript-eslint/no-use-before-define: ["off", { "allowNamedExports": true }] */
import { useEffect, useState } from 'react';
import { API_BASE_URL } from '../../appConfig';

const ADMI_BASE_URL = API_BASE_URL || 'http://dev.admi.de:8080';

export {
    useCalculatorData as default,
    // maybe more
};

function useCalculatorData() {
    const [calculatorData, setCalculatorData]: any = useState({});

    useEffect(() => {
        loadData(setCalculatorData);
    }, []);

    return calculatorData;
}

// load data from server
async function loadData(setCalculatorData: any) {
    const url = `${ADMI_BASE_URL}/v1/sales_input?time=${new Date().getTime()}"`;
    await fetch(url, { cache: 'no-store' })
        .then((response) => response.json())
        .then((data) => setCalculatorData(data));
}
