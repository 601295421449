import { Button, Input } from '@mui/joy';
import { formFile } from 'common/formikUtils';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Properties {
    name: string;
    formik: any;
}

function MultiImageInput(props: Properties) {
    const { name, formik } = props;

    const { t } = useTranslation();

    const [inputs, setInputs] = useState([<Input key="0" type="file" {...formFile(formik, `${name}[0]`)} />]);

    return (
        <Fragment>
            {inputs.map((input, index) => (
                <Fragment key={index}>
                    {input}
                    <br />
                </Fragment>
            ))}
            <Button
                variant="outlined"
                size="sm"
                onClick={() =>
                    setInputs([
                        ...inputs,
                        <Input key={inputs.length} type="file" {...formFile(formik, `${name}[${inputs.length}]`)} />,
                    ])
                }
            >
                {t('+ Add a new picture')}
            </Button>
        </Fragment>
    );
}

export default MultiImageInput;
