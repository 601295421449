import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { LocationSearch, GeoLocation, Layers, RealEstate } from '../global-tagger.dto';
import { loadRealEstateData } from '../global-tagger.actions';
import { CircularProgress } from '@mui/joy';

interface Properties {
    location: GeoLocation;
    realEstate: RealEstate;
    isLoading: boolean;
    selectedPlot: string;
    loadRealEstateData: any;
}

function RealEstateDetails(props: Properties) {
    const { location, realEstate, isLoading, selectedPlot, loadRealEstateData } = props;

    useEffect(() => {
        console.debug('RealEstateData');
    }, []);

    useEffect(() => {
        if (realEstate !== undefined) {
            let ps = realEstate.plots;
            if (selectedPlot !== '' && ps.length > 0) {
                ps = ps.filter((plot) => plot.code === selectedPlot);
            }
        }
    }, [realEstate, selectedPlot]);

    useEffect(() => {
        console.debug('RealEstateData.location', location);
        loadRealEstateData({
            location,
            radius: 200,
            layers: [Layers.SOLAR_POTENTIAL],
        });
    }, [location]);

    return <Fragment>{isLoading ? <CircularProgress /> : <></>}</Fragment>;
}

const mapStateToProps = function (state: any) {
    return {
        location: state.tagger.location,
        realEstate: state.tagger.realEstate,
        isLoading: state.tagger.isLoading,
        selectedPlot: state.tagger.selectedPlot,
    };
};

const mapDispatchToProps = function (dispatch: any) {
    return {
        loadRealEstateData: (dataSearch: LocationSearch) => dispatch(loadRealEstateData(dataSearch)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RealEstateDetails);
