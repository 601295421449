import { API_BASE_URL } from 'appConfig';
import { CreateProjectDto, GetProjectDto, MunicipalityResponseDto, UpdateProjectDto } from './projects.dto';
import { httpClient } from '../../infrastructure/admi-client/http-client';

export const admiProjectsService = {
    async createProject(createProjectDto: CreateProjectDto) {
        const response = await httpClient.post('/api/v1/projects', createProjectDto, {
            baseURL: API_BASE_URL,
        });

        return response.data;
    },

    async updateProject(id: string, updateProjectDto: UpdateProjectDto) {
        const response = await httpClient.put(`/v1/projects/${id}`, updateProjectDto, {
            baseURL: API_BASE_URL,
        });

        return response.data;
    },

    async getProject(id: string) {
        const response = await httpClient.get<GetProjectDto>(`/api/v1/projects/${id}`, {
            baseURL: API_BASE_URL,
        });

        return response.data;
    },

    async getProjects() {
        const response = await httpClient.get<{ data: GetProjectDto[] }>('/api/v1/projects', {
            baseURL: API_BASE_URL,
        });

        return response.data.data;
    },

    async getMunicipalities() {
        const response = await httpClient.get<MunicipalityResponseDto>('/api/v1/municipalities', {
            baseURL: API_BASE_URL,
        });

        return response.data.data;
    },
};
