import React, { useEffect, useRef, useState } from 'react';
import { Grid } from '@mui/joy';
import { connect } from 'react-redux';
import { setHighlightedBuilding, setLocation, setSelectedPlot } from '../global-tagger.actions';
import { Building, GeoLocation, Plot, RealEstate } from '../global-tagger.dto';
import useGoogleMapsApiLoader from 'infrastructure/google/useGoogleMapsApiLoader';
import { coordEach, feature, flatten } from '@turf/turf';
import { generateColorHashFromString } from 'common/generateColorHashFromString';
import Screenshot from './Screenshot';

interface Properties {
    location: GeoLocation;
    realEstate: RealEstate;
    selectedPlot: string;
    highlightedBuilding: string;
    showRoofSegments: boolean;
    setLocation: any;
    setSelectedPlot: any;
    setHighlightedBuilding: any;
}

function Map(props: Properties) {
    const {
        location,
        realEstate,
        selectedPlot,
        highlightedBuilding,
        showRoofSegments,
        setLocation,
        setSelectedPlot,
        setHighlightedBuilding,
    } = props;

    const [map, setMap] = useState<google.maps.Map | undefined>();
    const [marker, setMarker] = useState<google.maps.marker.AdvancedMarkerElement>();
    const [geocoder, setGeocoder] = useState<google.maps.Geocoder>();

    const [polygons, setPolygons] = useState<{
        [key: string]: google.maps.Polygon;
    }>({});
    const [roofSegmentPolygons, setRoofSegmentPolygons] = useState<{
        [key: string]: google.maps.Polygon;
    }>({});

    const loader = useGoogleMapsApiLoader();

    const mapRef = useRef(null);

    useEffect(() => {
        console.debug('Map');
        loadMap();
    }, []);

    useEffect(() => {
        console.debug('Map.location changed');
        refreshCenter();
        refreshPolygons();
        refreshRoofSegments();
        refreshSearchMarker();
    }, [location, realEstate, showRoofSegments]);

    useEffect(() => {
        if (map !== undefined && geocoder !== undefined) {
            google.maps.event.addListener(map, 'dragend', () => dragend(map, geocoder));
        }
    }, [map, geocoder]);

    useEffect(() => {
        refreshPolygonsHighlight();
    }, [highlightedBuilding]);

    useEffect(() => {
        refreshPolygons();
    }, [selectedPlot]);

    async function loadMap() {
        console.debug('loadMap');

        const mapOptions = {
            zoom: 18,
            center: { lat: location.lat, lng: location.lng },
            mapId: 'admi-tagger',
            mapTypeId: 'satellite' || google.maps.MapTypeId.SATELLITE,
        };
        const mapElement = document.getElementById('map');
        if (mapElement !== null) {
            const { Map } = (await loader.importLibrary('maps')) as google.maps.MapsLibrary;
            setMap(new Map(mapElement, mapOptions));

            const { Geocoder } = (await loader.importLibrary('geocoding')) as google.maps.GeocodingLibrary;
            setGeocoder(new Geocoder());
        }
    }

    async function refreshPolygons() {
        console.debug('refreshPolygons');
        if (map !== undefined && realEstate !== undefined) {
            Object.keys(polygons).forEach((key) => polygons[key].setMap(null));
            setPolygons({});

            realEstate.plots.forEach((plot: Plot) => {
                if (selectedPlot !== '' && plot.code !== selectedPlot) {
                    return;
                }

                let color = 'f5cb5a';
                if (selectedPlot === '') {
                    color = generateColorHashFromString(`plot_${plot.code}`);
                }

                plot.buildings.forEach((building: Building) => {
                    console.debug('building geometry', building.geometry);

                    const buildingsPolygons = flatten(feature(building.geometry));
                    buildingsPolygons.features.forEach((feature: any) => {
                        console.debug('feature', feature);
                        const polygonData: any[] = [];
                        coordEach(feature, (coord) => {
                            polygonData.push({ lat: coord[0], lng: coord[1] });
                        });

                        const polygon: google.maps.Polygon = new google.maps.Polygon({
                            paths: polygonData,
                            strokeColor: `#${color}`,
                            strokeOpacity: 1,
                            strokeWeight: 1,
                            fillColor: `#${color}`,
                            fillOpacity: highlightedBuilding == building.code ? 1 : 0.5,
                        });

                        attachPolygonInfoWindow(polygon, building);

                        google.maps.event.addListener(polygon, 'mouseover', () =>
                            setHighlightedBuilding(building.code),
                        );
                        google.maps.event.addListener(polygon, 'mouseout', () => setHighlightedBuilding(''));
                        google.maps.event.addListener(polygon, 'click', () => {
                            setSelectedPlot(plot.code !== selectedPlot ? plot.code : '');
                        });

                        polygon.setMap(map);
                        const statePolygons = polygons;
                        statePolygons[building.code] = polygon;
                        setPolygons(statePolygons);
                    });
                });
            });
        }
    }

    async function refreshRoofSegments() {
        console.debug('refreshRoofSegments', showRoofSegments);
        if (map !== undefined) {
            Object.keys(roofSegmentPolygons).forEach((key) => roofSegmentPolygons[key].setMap(null));

            realEstate.plots.forEach((plot: Plot) => {
                plot.buildings.forEach((building: Building) => {
                    if (showRoofSegments && building.roof !== undefined) {
                        for (const roofSegment of building.roof.roofSegments) {
                            console.debug('roofSegment', roofSegment);

                            const boundingBox = {
                                north: roofSegment.bbox[0],
                                east: roofSegment.bbox[1],
                                south: roofSegment.bbox[2],
                                west: roofSegment.bbox[3],
                            };

                            const roofSegmentPolygonData = [
                                { lat: boundingBox.north, lng: boundingBox.west },
                                { lat: boundingBox.north, lng: boundingBox.east },
                                { lat: boundingBox.south, lng: boundingBox.east },
                                { lat: boundingBox.south, lng: boundingBox.west },
                                { lat: boundingBox.north, lng: boundingBox.west },
                            ];
                            const roofColor = generateColorHashFromString(`roof_${building.code}`);
                            const roofSegmentPolygon: google.maps.Polygon = new google.maps.Polygon({
                                paths: roofSegmentPolygonData,
                                strokeColor: `#${roofColor}`,
                                strokeOpacity: 1,
                                strokeWeight: 1,
                                fillColor: `#${roofColor}`,
                                fillOpacity: 0.5,
                            });
                            const statePolygons = roofSegmentPolygons;
                            statePolygons[roofSegment.code] = roofSegmentPolygon;
                            setRoofSegmentPolygons(statePolygons);
                            roofSegmentPolygon.setMap(map);
                        }
                    }
                });
            });
        }
    }

    async function dragend(map: google.maps.Map, geocoder: google.maps.Geocoder): Promise<void> {
        console.debug('dragend', map, geocoder);
        if (map !== undefined && geocoder !== undefined) {
            try {
                const geocoderResponse: google.maps.GeocoderResponse = await geocoder.geocode({
                    location: map.getCenter(),
                });
                console.debug('geocoderResponse', geocoderResponse);
                setLocation({
                    address: geocoderResponse.results[0].formatted_address,
                    lat: map.getCenter()?.lat(),
                    lng: map.getCenter()?.lng(),
                });
            } catch (e) {
                console.error('Geocoder failed due to: ' + e);
            }
        }
    }

    async function refreshCenter(): Promise<void> {
        console.debug('refreshCenter', location);

        if (map !== undefined) {
            const centerLatLng = new google.maps.LatLng(location.lat, location.lng);
            map.setCenter(centerLatLng);
        }
    }

    async function refreshSearchMarker(): Promise<void> {
        console.debug('refreshSearchMarker', location);

        if (map !== undefined) {
            const { AdvancedMarkerElement } = (await loader.importLibrary('marker')) as google.maps.MarkerLibrary;
            if (marker !== undefined) {
                marker.map = null;
                setMarker(marker);
            }

            setMarker(
                new AdvancedMarkerElement({
                    position: { lat: location.lat, lng: location.lng },
                    map,
                    title: location.address + '\n' + location.lat + ',' + location.lng,
                }),
            );
        }
    }

    function refreshPolygonsHighlight(): void {
        console.debug('refreshPolygonsHighlight');

        if (map !== undefined && polygons !== undefined) {
            Object.keys(polygons).forEach((key) => {
                polygons[key].setOptions({
                    fillOpacity: key == highlightedBuilding ? 1 : 0.5,
                });
            });
        }
    }

    return (
        <Grid xs={12} container className="map" justifyContent="center">
            <Grid style={{ height: '5%', width: '100%' }}>
                <Screenshot mapRef={mapRef} />
            </Grid>
            <Grid style={{ height: '95%', width: '100%' }}>
                <div ref={mapRef} id="map" style={{ height: '100%', width: '100%' }} />
            </Grid>
        </Grid>
    );

    function attachPolygonInfoWindow(polygon: google.maps.Polygon, building: Building) {
        const infoWindow = new google.maps.InfoWindow();

        let kwp = 0;
        let kwhPerKwp = 0;
        for (const key in building.roof?.roofSegments) {
            const roofSegment = building.roof?.roofSegments[key];
            kwp += roofSegment.roofSegmentSolarPotential?.kwp;
            kwhPerKwp += roofSegment.roofSegmentSolarPotential?.kwhPerKwp;
        }
        kwhPerKwp = kwhPerKwp / building.roof?.roofSegments.length;

        google.maps.event.addListener(polygon, 'mouseover', (e: any) => {
            infoWindow.setContent(
                `<b>Building Function:</b> ${building.buildingFunction.name}<br/>` +
                `<b>Roof Area:</b> ${building.roof?.area?.toFixed(2)} m²<br/>` +
                `<b>Potential:</b> ${kwp.toFixed(2)} kWp<br/>` +
                `<b>Efficiency (avg):</b> ${kwhPerKwp.toFixed(2)} kWh/kWp<br/>`,
            );
            const latLgn: google.maps.LatLng = e.latLng;
            let lat = e.latLng.lat();
            lat = lat + 0.0001; // I am doing this in order to show the infoWindow a little bit above the polygon
            infoWindow.setPosition(new google.maps.LatLng(lat, latLgn.lng()));
            infoWindow.open(map);
        });

        google.maps.event.addListener(polygon, 'mouseout', () => {
            infoWindow.close();
        });
    }
}

const mapStateToProps = function (state: any) {
    return {
        location: state.tagger.location,
        realEstate: state.tagger.realEstate,
        selectedPlot: state.tagger.selectedPlot,
        highlightedBuilding: state.tagger.highlightedBuilding,
        showRoofSegments: state.tagger.showRoofSegments,
    };
};

const mapDispatchToProps = function (dispatch: any) {
    return {
        setLocation: (location: GeoLocation) => dispatch(setLocation(location)),
        setSelectedPlot: (selectedPlot: string) => dispatch(setSelectedPlot(selectedPlot)),
        setHighlightedBuilding: (highlightedBuilding: string) => dispatch(setHighlightedBuilding(highlightedBuilding)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Map);
