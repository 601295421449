import React, { Fragment, useRef, useState } from 'react';

import { Link } from 'react-router-dom';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import './sales.css';
import SalesChart from './SalesChart';
import useCalculatorData from './useCalculatorData';
import PlotImage from '../real-estate/components/PlotImage';
import { CheckBoxOutlined, SaveAlt } from '@mui/icons-material';
import Button from '@mui/joy/Button';
import useScreenshot from '../../infrastructure/image/useScreenshot';
import { useTranslation } from 'react-i18next';

export default function SalesPage() {
    const calculatorData = useCalculatorData();
    console.debug('SalesPage', 'calculatorData', calculatorData);
    const isCalculationDataLoaded = Object.values(calculatorData).length > 0;

    const [salesRep, setSalesRep] = useState('Freddi');
    const [liegenschaftIDs, setLiegenschaftIDs] = React.useState<string[]>([]);
    const [displayCount, setDisplayCount] = useState(1);

    const allLiegenschaftsIDs = Object.keys(calculatorData).filter((key) => key);

    const [energyConsumption, setEnergyConsumption] = useState<string>('estimated');

    const salesPrintRef = useRef(null);

    const screenshot = useScreenshot();

    async function downloadImage(filename: string) {
        if (salesPrintRef.current === null) {
            return;
        }

        const canvas: HTMLCanvasElement = await screenshot.screenshot(salesPrintRef.current, {
            top: 0,
            right: 0,
            useCORS: true,
        });

        const link = document.createElement('a');
        link.download = filename;
        link.href = canvas.toDataURL('image/png');
        link.click();
    }

    return (
        <>
            {getPrintControls(
                salesRep,
                setSalesRep,
                liegenschaftIDs,
                setLiegenschaftIDs,
                allLiegenschaftsIDs,
                displayCount,
                setDisplayCount,
                energyConsumption,
                setEnergyConsumption,
                downloadImage,
            )}

            {displayCount === 1 && (
                <div className="sales sales_count1" ref={salesPrintRef}>
                    {/*<hr /> */}
                    <div id="objects">
                        {isCalculationDataLoaded &&
                            Object.entries(calculatorData)
                                .filter(([key, obj]: any) => obj?.optimiert && obj && liegenschaftIDs.includes(key))
                                .filter((val: any, i: any) => i < displayCount)
                                .map(([key, obj]: any) => (
                                    <div key={key}>
                                        <h3>{obj.optimiert.name}</h3>
                                        <article>
                                            <div className="section_img">
                                                <div className="estimatedEnergyUse">
                                                    {energyConsumption == 'estimated' ? 'Geschätzter' : 'Gemessener'}{' '}
                                                    Stromverbrauch:{' '}
                                                    {formatStringToGermanNumber(obj.optimiert.Jahresverbrauch)} kWh
                                                </div>
                                                <PlotImage uuid={key} overrideImage={obj.optimiert.img} />
                                            </div>

                                            <div className="section_variants">
                                                {Object.entries(obj).map(([variantKey, variant]: any) => (
                                                    <div key={variantKey}>
                                                        <div className="subtitle">
                                                            <h4 className="section_headline" key="section_headline">
                                                                {variant.type === 'optimiert' ? (
                                                                    <div>
                                                                        <span className="admi">Admi</span> optimiert
                                                                        {variant.Förderquote && (
                                                                            <Fragment>
                                                                                <span className="quote">
                                                                                    Förderquote: {variant.Förderquote}
                                                                                </span>
                                                                                <span className="funding">
                                                                                    <span>
                                                                                        80% Eigenverbrauch &nbsp;
                                                                                        <CheckBoxOutlined htmlColor="#f5cb5b" />
                                                                                    </span>
                                                                                    <span>
                                                                                        Batteriespeicher &lt; 2 x PV
                                                                                        Leistung &nbsp;
                                                                                        <CheckBoxOutlined htmlColor="#f5cb5b" />
                                                                                    </span>
                                                                                </span>
                                                                            </Fragment>
                                                                        )}
                                                                    </div>
                                                                ) : (
                                                                    'Vollbelegung'
                                                                )}
                                                            </h4>
                                                        </div>
                                                        <section>
                                                            <div className="section_text_left">
                                                                <h5>Energieübersicht</h5>
                                                                <table>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>Produktion:</td>
                                                                            <td>
                                                                                {formatStringToGermanNumber(
                                                                                    variant.KapazitätJahr,
                                                                                )}{' '}
                                                                                kWh
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Leistung:</td>
                                                                            <td>
                                                                                {' '}
                                                                                {formatStringToGermanNumber(
                                                                                    variant.KapazitätPeak,
                                                                                )}{' '}
                                                                                kWp
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Speicher:</td>
                                                                            <td>
                                                                                {formatStringToGermanNumber(
                                                                                    variant.Batteriespeichergröße,
                                                                                )}{' '}
                                                                                kWh
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                CO<sub>2</sub>-Reduktion:
                                                                                <div className="yearlyLabel">
                                                                                    (jährlich)
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                {formatStringToGermanNumber(
                                                                                    variant.CO2Einsparung,
                                                                                    1,
                                                                                )}{' '}
                                                                                t<br />
                                                                                {/* number from https://www.google.com/search?q=co+aufnahme+per+baum */}
                                                                                ≈
                                                                                {formatStringToGermanNumber(
                                                                                    (variant.CO2Einsparung * 1000) /
                                                                                        24.62,
                                                                                    0,
                                                                                )}{' '}
                                                                                Bäume
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                            <div className="section_text_right">
                                                                <h5>Finanzübersicht</h5>

                                                                <table>
                                                                    <tbody>
                                                                        {variant.Gesamtkosten && (
                                                                            <tr>
                                                                                <td>Gesamtkosten:</td>
                                                                                <td>
                                                                                    {formatStringToGermanNumber(
                                                                                        variant.Gesamtkosten,
                                                                                    )}{' '}
                                                                                    €
                                                                                </td>
                                                                            </tr>
                                                                        )}
                                                                        {(variant.Förderquote && variant.type === 'optimiert') && (
                                                                            <tr>
                                                                                <td>Eigenanteil:</td>
                                                                                <td>
                                                                                    {formatStringToGermanNumber(
                                                                                        variant.Kosten,
                                                                                    )}{' '}
                                                                                    €
                                                                                </td>
                                                                            </tr>
                                                                        )}
                                                                        <tr>
                                                                            <td>Gesamteinsparung:</td>
                                                                            <td>
                                                                                {formatStringToGermanNumber(
                                                                                    variant.Gesamtsaldo30Jahre,
                                                                                )}{' '}
                                                                                €
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Amortisationszeit:</td>
                                                                            <td>
                                                                                {formatStringToGermanNumber(
                                                                                    variant.Amortisationsdauer,
                                                                                )}{' '}
                                                                                Jahr
                                                                                {Number(variant.Amortisationsdauer) ===
                                                                                1
                                                                                    ? ''
                                                                                    : 'e'}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                            <div className="section_chart">
                                                                <SalesChart
                                                                    variant={variant}
                                                                    width={400}
                                                                    height={200}
                                                                />
                                                            </div>
                                                        </section>
                                                    </div>
                                                ))}
                                            </div>
                                        </article>
                                    </div>
                                ))}

                        {!isCalculationDataLoaded && 'Loading...'}
                    </div>
                    {/* <hr /> */}
                </div>
            )}

            {displayCount === 2 && (
                <div className="sales sales_count2" ref={salesPrintRef}>
                    <header>
                        <h1>VORANALYSE</h1>
                        <h2>Ihre Liegenschaften haben großes Potenzial</h2>
                    </header>

                    <div id="objects">
                        {isCalculationDataLoaded &&
                            Object.entries(calculatorData)
                                .filter(([key, obj]: any) => obj?.optimiert && obj && liegenschaftIDs.includes(key))
                                .filter((val: any, i: any) => i < displayCount)
                                .map(([key, obj]: any) => (
                                    <article key={key}>
                                        <h3>PV-Potenzial: {obj.optimiert.name}</h3>
                                        <div>
                                            {Object.entries(obj).map(([variantKey, variant]: any) => (
                                                <section key={variantKey}>
                                                    <h4 className="section_headline">
                                                        {variant.type === 'optimiert' ? (
                                                            <div>
                                                                <span className="admi">Admi</span> optimiert
                                                            </div>
                                                        ) : (
                                                            'Vollbelegung'
                                                        )}
                                                    </h4>
                                                    <div className="section_img">
                                                        {variant?.img && <img src={variant.img} alt="" />}
                                                    </div>
                                                    <div className="section_chart">
                                                        <SalesChart variant={variant} width={400} height={200} />
                                                    </div>
                                                    <div className="section_text_left">
                                                        <h5>Energieübersicht</h5>
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Produktion:</td>
                                                                    <td>
                                                                        {formatStringToGermanNumber(
                                                                            variant.KapazitätJahr,
                                                                        )}{' '}
                                                                        kWh
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Leistung:</td>
                                                                    <td>
                                                                        {formatStringToGermanNumber(
                                                                            variant.KapazitätPeak,
                                                                        )}{' '}
                                                                        kWp
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Speicher:</td>
                                                                    <td>
                                                                        {formatStringToGermanNumber(
                                                                            variant.Batteriespeichergröße,
                                                                        )}{' '}
                                                                        kWh
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        CO<sub>2</sub>-Reduktion:
                                                                    </td>
                                                                    <td>
                                                                        {formatStringToGermanNumber(
                                                                            variant.CO2Einsparung,
                                                                        )}{' '}
                                                                        t/Jahr
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="section_text_right">
                                                        <h5>Finanzübersicht</h5>
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Kosten (Kommune):</td>
                                                                    <td>
                                                                        {formatStringToGermanNumber(variant.Kosten)} €
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Gesamteinsparung:</td>
                                                                    <td>
                                                                        {formatStringToGermanNumber(
                                                                            variant.Gesamtsaldo30Jahre,
                                                                        )}{' '}
                                                                        €
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Amortisationszeit:</td>
                                                                    <td>
                                                                        {variant.Amortisationsdauer} Jahr
                                                                        {Number(variant.Amortisationsdauer) === 1
                                                                            ? ''
                                                                            : 'e'}
                                                                    </td>
                                                                </tr>
                                                                {variant.Gesamtkosten && (
                                                                    <Fragment>
                                                                        <tr>
                                                                            <td>Gesamtkosten PV = </td>
                                                                            <td>
                                                                                {formatStringToGermanNumber(
                                                                                    variant.Gesamtkosten,
                                                                                )}{' '}
                                                                                €
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>(vor Förderung)</td>
                                                                        </tr>
                                                                    </Fragment>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </section>
                                            ))}
                                        </div>
                                    </article>
                                ))}

                        {!isCalculationDataLoaded && 'Loading...'}
                    </div>

                    <hr />

                    <header>
                        <h1>KONTAKT</h1>
                        <h2>Wir freuen uns auf eine mögliche Zusammenarbeit</h2>
                    </header>

                    <div className={'contact ' + (salesRep === 'Freddi' ? 'freddi' : '')}>
                        <div className="frame">
                            <div className="frame_inner">
                                {salesRep === 'Freddi' && (
                                    <>
                                        <h3>Freddi Lange</h3>
                                        Geschäftsführer, admi Kommunal GmbH
                                        <br />
                                        freddi.lange@admi.de
                                        <br />
                                        05252 9154821
                                        <br />
                                        www.admi.de
                                        <br />
                                    </>
                                )}
                                {salesRep === 'Paul' && (
                                    <>
                                        <h3>Paul Thiekötter</h3>
                                        Geschäftsführer, admi Kommunal GmbH
                                        <br />
                                        paul.thiekoetter@admi.de
                                        <br />
                                        05252 9154823
                                        <br />
                                        www.admi.de
                                        <br />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

function formatStringToGermanNumber(numStr: any, digits = -1) {
    if (digits > -1) {
        numStr = Number(numStr).toFixed(digits);
    }

    return Number(numStr).toLocaleString('de-DE');
}

function getPrintControls(
    salesRep: string,
    setSalesRep: any,
    liegenschaftIDs: any,
    setLiegenschaftIDs: any,
    allLiegenschaftsIDs: any,
    displayCount: number,
    setDisplayCount: any,
    energyConsumption: any,
    setEnergyConsumption: any,
    downloadImage: any,
) {
    const { t } = useTranslation();

    const changeSalesRep = (e: any) => {
        setSalesRep(e.target.value);
    };
    const changeliegenschaftIDs = (event: SelectChangeEvent<typeof liegenschaftIDs>) => {
        const {
            target: { value },
        } = event;
        setLiegenschaftIDs(typeof value === 'string' ? value.split(',') : value);
    };

    const changeDisplayCount = (e: any) => {
        setDisplayCount(e.target.value);
    };

    if (allLiegenschaftsIDs.length && !liegenschaftIDs.length) {
        setLiegenschaftIDs([allLiegenschaftsIDs[0], allLiegenschaftsIDs[1]]);
    }

    return (
        <div className="printControls">
            <Link to="/demos/v2" style={{ padding: 15, display: 'inline-block' }}>
                <ArrowBackIcon fontSize="large" />
            </Link>

            <FormControl sx={{ m: 1, width: 150 }}>
                <InputLabel id="demo-simple-select-label">SalesRep</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={salesRep}
                    label="SalesRep"
                    onChange={changeSalesRep}
                >
                    <MenuItem value={'Freddi'}>Freddi</MenuItem>
                    <MenuItem value={'Paul'}>Paul</MenuItem>
                </Select>
            </FormControl>

            <FormControl sx={{ m: 1, width: 150 }}>
                <InputLabel id="display-type-select-label">Display-Type</InputLabel>
                <Select
                    labelId="display-type-select-label"
                    id="display-type-select"
                    value={displayCount}
                    label="displayType"
                    onChange={changeDisplayCount}
                >
                    <MenuItem value={1}>single</MenuItem>
                    <MenuItem value={2}>double</MenuItem>
                </Select>
            </FormControl>

            <FormControl sx={{ m: 1, width: 400 }}>
                <InputLabel id="demo-multiple-checkbox-label">Liegenschaften</InputLabel>
                <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={liegenschaftIDs}
                    onChange={changeliegenschaftIDs}
                    input={<OutlinedInput label="Liegenschaften" />}
                    renderValue={(selected) => selected.join(', ')}
                    // MenuProps={MenuProps}
                >
                    {allLiegenschaftsIDs.map((liegenschaftsID: any) => (
                        <MenuItem key={liegenschaftsID} value={liegenschaftsID}>
                            <Checkbox checked={liegenschaftIDs.indexOf(liegenschaftsID) > -1} />
                            <ListItemText primary={liegenschaftsID} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl sx={{ m: 1, width: 200 }}>
                <InputLabel id="energy-consumption-type">Stromverbrauch</InputLabel>
                <Select
                    labelId="energy-consumption-type"
                    id="energy-consumption-checkbox"
                    value={energyConsumption}
                    onChange={(e: SelectChangeEvent<HTMLSelectElement>) =>
                        e.target.value !== '' ? setEnergyConsumption(e.target.value as string) : null
                    }
                    input={<OutlinedInput label="Liegenschaften" />}
                >
                    <MenuItem value="estimated">Geschätzter</MenuItem>
                    <MenuItem value="actual">Gemessener</MenuItem>
                </Select>
            </FormControl>

            <FormControl sx={{ m: 2, width: 150 }}>
                <Button variant="soft" onClick={() => downloadImage('slide.png')} startDecorator={<SaveAlt />}>
                    {t('Save Image')}
                </Button>
            </FormControl>
        </div>
    );
}
