import React from 'react';
import { Box, FormControl, FormLabel, Typography, Input, Select, Option, Switch } from '@mui/joy';
import { formCheckbox, formFieldBuilder, formFile, handleSelect } from '../../common/formikUtils';
import { ROOF_CLADDINGS, ROOF_TYPES } from './selectOptions';
import MultiImageInput from './components/MultImageInput';

export default function FragmentDach({ formik }: any) {
    const formField = (name: string) => formFieldBuilder(formik, name);

    return (
        <>
            <Typography level="h3">Dach</Typography>

            <Box sx={{ display: 'flex', gap: 1 }}>
                <FormControl required>
                    <FormLabel>Dachtyp</FormLabel>
                    <Select
                        sx={{ width: '20rem' }}
                        value={formik.values.roof_type}
                        onChange={handleSelect(formik, 'DACHTYP')}
                    >
                        {ROOF_TYPES.map((roof_type) => (
                            <Option key={roof_type} value={roof_type}>
                                {roof_type}
                            </Option>
                        ))}
                    </Select>
                </FormControl>

                <FormControl required>
                    <FormLabel>Dacheindeckung</FormLabel>
                    <Select
                        sx={{ width: '20rem' }}
                        value={formik.values.roof_cladding}
                        onChange={handleSelect(formik, 'DACHHAUT')}
                    >
                        {ROOF_CLADDINGS.map((roof_cladding) => (
                            <Option key={roof_cladding} value={roof_cladding}>
                                {roof_cladding}
                            </Option>
                        ))}
                    </Select>
                </FormControl>
            </Box>

            <FormControl>
                <FormLabel>Deckmaß vertikal (in cm)</FormLabel>
                <Input type="number" placeholder="Deckmaß vertikal (in cm)" {...formField('DECKMASS_VERTIKAL')} />
            </FormControl>
            <FormControl>
                <FormLabel>Aufnahme Deckmaß vertikal</FormLabel>
                <Input type="file" {...formFile(formik, 'AUFNAHME_DECKMASS_VERTIKAL')} />
            </FormControl>

            <FormControl>
                <FormLabel>Deckmaß horizontal (in cm)</FormLabel>
                <Input type="number" placeholder="Deckmaß horizontal (in cm)" {...formField('DECKMASS_HORIZONTAL')} />
            </FormControl>
            <FormControl>
                <FormLabel>Aufnahme Deckmaß horizontal</FormLabel>
                <Input type="file" {...formFile(formik, 'AUFNAHME_DECKMASS_HORIZONTAL')} />
            </FormControl>

            <FormControl>
                <FormLabel>Aufnahme Dachstuhl</FormLabel>
                <MultiImageInput name="AUFNAHME_DACHSTUHL" formik={formik} />
            </FormControl>

            <FormControl>
                <FormLabel>Aufnahme Sparrenabstand</FormLabel>
                <Input type="file" {...formFile(formik, 'AUFNAHME_SPARRENABSTAND')} />
            </FormControl>
            <FormControl>
                <FormLabel>Sparrenabstand</FormLabel>
                <Input type="number" placeholder="Sparrenabstand" {...formField('SPARRENABSTAND')} />
            </FormControl>

            <FormControl>
                <FormLabel>Aufnahme Sparrenbreite</FormLabel>
                <Input type="file" {...formFile(formik, 'AUFNAHME_SPARRENBREITE')} />
            </FormControl>
            <FormControl>
                <FormLabel>Sparrenbreite</FormLabel>
                <Input type="number" placeholder="Sparrenbreite" {...formField('SPARRENBREITE')} />
            </FormControl>

            <Typography
                component="label"
                startDecorator={<Switch sx={{ ml: 1 }} {...formCheckbox(formik, 'AUFSPARRENDAEMMUNG')} />}
            >
                Aufsparrendämmung vorhanden
            </Typography>

            <FormControl required>
                <FormLabel>Aufnahme Gesamt</FormLabel>
                <Input type="file" {...formFile(formik, 'AUFNAHME_DROHNE_GESAMT')} />
            </FormControl>
        </>
    );
}
