import React, { Fragment, useEffect, useState } from 'react';
import { Button, CircularProgress, Sheet, Table, Typography } from '@mui/joy';
import { Link } from 'react-router-dom';

import { admiProjectsService } from './admi-projects.service';
import { GetProjectDto } from './projects.dto';

export function ListProjectsPage() {
    const [projects, setProjects] = useState<GetProjectDto[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        loadProjects();
    }, []);

    async function loadProjects() {
        setIsLoading(true);

        try {
            const projects = await admiProjectsService.getProjects();
            setProjects(projects);
        } catch (error) {
            console.error(error);
        }

        setIsLoading(false);
    }

    return (
        <Fragment>
            {isLoading ? (
                    <CircularProgress />
                ) : (
                    <Fragment>
                        <Typography level="h3">Projekte</Typography>
                        <Link to="/dev/projects/create">
                            <Button size="sm">Neu</Button>
                        </Link>

                        <Sheet>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Kommune</th>
                                        <th>Created At</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {projects.map((project) => (
                                        <tr key={project.uuid}>
                                            <td>{project.name}</td>
                                            <td>{project.municipality.name}</td>
                                            <td>{project.createdAt.toString()}</td>
                                            <td>
                                                <Link to={`/dev/projects/${project.uuid}`}>
                                                    <Button size="sm">Bearbeiten</Button>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Sheet>
                    </Fragment>
                )}
        </Fragment>
    );
}
