import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, FormControl, FormLabel, Input, Stack, Typography, Autocomplete, CircularProgress } from '@mui/joy';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';

import { formFieldBuilder, handleSelect } from 'common/formikUtils';

import { admiProjectsService } from './admi-projects.service';
import { GetProjectDto, Municipality } from './projects.dto';

export function CreateEditProjectPage() {
    const params = useParams();
    const [project, setProject] = useState<GetProjectDto | null>(null);
    const [municipalities, setMunicipalities] = useState<Municipality[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const [selectedMunicipality, setSelectedMunicipality] = useState<{label: string, code: string} | null>(null);

    const projectId = params.projectId;

    useEffect(() => {
        loadProject();
    }, [projectId]);

    useEffect(() => {
        admiProjectsService.getMunicipalities().then((municipalities) => setMunicipalities(municipalities));
    }, [])

    const formRef = useRef<HTMLFormElement>(null);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: project?.name ?? '',
            municipality: project?.municipality.code ?? '',
        },
        onSubmit: async (/*values*/) => {
            // const projectDto = {
            //     name: values.name,
            //     municipality: values.municipality,
            // };

            // if (projectId) {
            //   await admiProjectsService.updateProject(projectId, projectDto);
            // } else {
            //   await admiProjectsService.createProject(projectDto);
            // }

            formik.setStatus('Projekt erfolgreich gespeichert.');
            formRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        },
    });

    const formField = (name: string) => formFieldBuilder(formik, name);

    async function loadProject() {
        setIsLoading(true);
        if (projectId) {
            admiProjectsService.getProject(projectId).then((project) => {
                setProject(project);
                setSelectedMunicipality({ label: project.municipality.name, code: project.municipality.code });
            });
        } else {
            setProject(null);
        }
        setIsLoading(false);
    }

    return (
        <form onSubmit={formik.handleSubmit} ref={formRef}>
            {isLoading ? (
                    <CircularProgress />
                ) : (
                    <Stack spacing={1.5}>
                        {formik.status && <Alert color="success">{formik.status}</Alert>}

                        {!projectId && <Typography level="h3">Projekt anlegen</Typography>}
                        {projectId && <Typography level="h3">Projekt bearbeiten</Typography>}

                        <Typography level="h4">Projektinformationen</Typography>
                        <FormControl required>
                            <FormLabel>Projektname</FormLabel>
                            <Input {...formField('name')} />
                        </FormControl>

                        <FormControl required>
                            <FormLabel>Kommune</FormLabel>

                            <Autocomplete
                                onChange={(_, newValue) => {handleSelect(formik, 'municipality'); setSelectedMunicipality(newValue)}}
                                options={municipalities.map((m) => { return { label: m.name, code: m.code } })}
                                value={selectedMunicipality}
                            />
                        </FormControl>

                        <Button type="submit" loading={formik.isSubmitting}>
                            Speichern
                        </Button>
                    </Stack>
                )}
        </form>
    );
}
