import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from 'reportWebVitals';

import App from 'app/App';
import { Provider } from 'react-redux';
import store from './store';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import i18n from './infrastructure/i18n/i18next.client'; // this is what makes the translation work. DO NOT REMOVE IT

const root = document.getElementById('root') as HTMLDivElement;
ReactDOM.createRoot(root).render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>,
);

reportWebVitals();
