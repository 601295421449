export enum Optimizer {
    ROI = 'roi',
    FULL_ROOF = 'full_roof',
    RHEINISCHES_REVIER_FUNDING = 'rheinisches_revier_funding',
}

export enum LoadProfile {
    SCHULE = 'SCHULE',
    BAUHOF = 'BAUHOF',
    BUECHEREI = 'BUECHEREI',
    FEUERWEHR = 'FEUERWEHR',
    FREIBAD = 'FREIBAD',
    FRIEDHOF = 'FRIEDHOF',
    HALLENBAD = 'HALLENBAD',
    JUGENDZENTRUM = 'JUGENDZENTRUM',
    KINDERGARTEN = 'KINDERGARTEN',
    KLAERWERK = 'KLAERWERK',
    MUSEUM = 'MUSEUM',
    PUMPWERK = 'PUMPWERK',
    SPORTPLATZ_AN_SCHULE = 'SPORTPLATZ_AN_SCHULE',
    SPORTPLATZ_OHNE_SCHULE = 'SPORTPLATZ_OHNE_SCHULE',
    VERWALTUNGSGEBAEUDE = 'VERWALTUNGSGEBAEUDE',
    WASSERWERK = 'WASSERWERK',
    WOHNHEIM_FUER_GEFLUECHTETE = 'WOHNHEIM_FUER_GEFLUECHTETE',
}
