import React from 'react';
import { Box, FormControl, FormLabel, Typography, Input, Switch } from '@mui/joy';
import { formCheckbox, formFieldBuilder } from '../../common/formikUtils';

export default function FragmentAllgemein({ formik }: any) {
    const formField = (name: string) => formFieldBuilder(formik, name);

    return (
        <>
            <FormControl required>
                <FormLabel>Gebäudename</FormLabel>
                <Input placeholder="Gebäudename" {...formField('GEBAUDENAME')} />
            </FormControl>

            <Box sx={{ display: 'flex', gap: 1 }}>
                <FormControl required>
                    <FormLabel>Adresse</FormLabel>
                    <Input placeholder="Straße und Hausnummer" {...formField('ADRESSE')} />
                </FormControl>

                <FormControl required>
                    <FormLabel>PLZ</FormLabel>
                    <Input placeholder="PLZ" {...formField('PLZ')} />
                </FormControl>
            </Box>

            <FormControl required>
                <FormLabel>Name der Kommune</FormLabel>
                <Input placeholder="Name der Kommune" {...formField('KOMMUNE')} />
            </FormControl>

            <FormControl required>
                <FormLabel>Abschlussdatum</FormLabel>
                <Input type="date" placeholder="Abschlussdatum" {...formField('ENDDATUM_UMSETZUNG')} />
            </FormControl>

            <FormControl required>
                <FormLabel>Zeitrahmen Umsetzung</FormLabel>
                <Input type="text" placeholder="Zeitrahmen Umsetzung" {...formField('ZEITRAHMEN_UMSETZUNG')} />
            </FormControl>

            <Typography
                component="label"
                startDecorator={<Switch sx={{ ml: 1 }} {...formCheckbox(formik, 'GEFOERDERT')} />}
            >
                Gefördertes Projekt
            </Typography>

            <FormControl required>
                <Typography level="h3">Allgemein</Typography>

                <FormLabel>Dachfläche Potenzial (in m²)</FormLabel>
                <Input
                    type="number"
                    placeholder="Dachfläche Potenzial (in m²)"
                    {...formField('DACHFLAECHE_POTENZIAL')}
                />
            </FormControl>

            <FormControl required>
                <FormLabel>Anzahl Dachflächen</FormLabel>
                <Input
                    type="number"
                    placeholder="Anzahl Dachflächen"
                    slotProps={{ input: { min: 1 } }}
                    {...formField('ANZAHL_DACHFLAECHEN')}
                />
            </FormControl>

            <FormControl required>
                <FormLabel>Größe der Anlage/Spitzenleistung (in kWp)</FormLabel>
                <Input type="number" placeholder="Größe der Anlage (in kWp)" {...formField('PEAK_LEISTUNG')} />
            </FormControl>

            <FormControl required>
                <FormLabel>Batteriekapazität (in kWh)</FormLabel>
                <Input type="number" placeholder="Batteriekapazität (in kWh)" {...formField('BATTERIE_KAPAZITAET')} />
            </FormControl>
        </>
    );
}
