import { TYPES } from './global-tagger.actions';
import { GeoLocation, Layers, RealEstate } from './global-tagger.dto';

export interface State {
    location: GeoLocation;
    errorMessage: string;
    realEstate: RealEstate;
    selectedPlot: string;
    highlightedBuilding: string;
    showRoofSegments: boolean;
    useQA: boolean;
    isLoading: boolean;
}

const initialState: State = {
    location: {
        address: 'Oberbilker Allee 244, 40227 Düsseldorf',
        lat: 51.2108431,
        lng: 6.802931999999999,
    },
    errorMessage: '',
    realEstate: {
        count: 0,
        plots: [],
        locationSearch: {
            lat: 51.2108431,
            lng: 6.802931999999999,
            radius: 1000,
            layers: [Layers.SOLAR_POTENTIAL],
        },
    },
    selectedPlot: '',
    highlightedBuilding: '',
    showRoofSegments: false,
    useQA: false,
    isLoading: false,
};

export const tagger = (state: any = initialState, action: any) => {
    switch (action.type) {
        case TYPES.SET_LOCATION:
            return Object.assign({}, state, { location: action.location });

        case TYPES.LOAD_REAL_ESTATE_DATA_REQUESTED:
            return Object.assign({}, state, { isLoading: true });
        case TYPES.LOAD_REAL_ESTATE_DATA_SUCCEEDED:
            return Object.assign({}, state, {
                isLoading: false,
                realEstate: action.realEstate,
            });
        case TYPES.LOAD_REAL_ESTATE_DATA_FAILED:
            return Object.assign({}, state, {
                isLoading: false,
                errorMessage: action.errorMessage,
            });

        case TYPES.SET_SELECTED_PLOT:
            return Object.assign({}, state, { selectedPlot: action.selectedPlot });

        case TYPES.SET_HIGHLIGHTED_BUILDING:
            return Object.assign({}, state, {
                highlightedBuilding: action.highlightedBuilding,
            });

        case TYPES.SET_SHOW_ROOF_SEGMENTS:
            return Object.assign({}, state, {
                showRoofSegments: action.showRoofSegments,
            });


        case TYPES.SET_USE_QA:
            return Object.assign({}, state, { useQA: action.useQA });

        default:
            return state;
    }
};
