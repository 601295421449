import React from 'react';

export default function Demo2Page() {
    return (
        <>
            <h1>Sales Page</h1>

            <p>
                here could be a whole admin interface managing calculator data, with a button generating the pdf (or at
                least the print view)
            </p>

            <a href="/demos/sales"> Open Print View </a>
        </>
    );
}
