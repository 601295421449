import React from 'react';
import { FormControl, FormLabel, Typography, Input, Select, Option, Box } from '@mui/joy';
import { formFieldBuilder, formFile, handleSelect } from '../../common/formikUtils';
import { STOERFLAECHEN } from './selectOptions';
import { useTranslation } from 'react-i18next';

export default function FragmentDachflaeche({ formik, index }: any) {
    const formField = (name: string) => formFieldBuilder(formik, name);
    const fieldPrefix = `DACHFLAECHE[${index}]`;

    const { t } = useTranslation();

    return (
        <>
            <Typography level="h3">Dachfläche {index + 1}</Typography>

            <Box sx={{ display: 'flex', gap: 1 }}>
                <FormControl required>
                    <FormLabel>Ausrichtung (Himmelsrichtung)</FormLabel>
                    <Input
                        type="text"
                        placeholder="Ausrichtung (Himmelsrichtung)"
                        {...formField(`${fieldPrefix}.AUSRICHTUNG`)}
                    />
                </FormControl>

                <FormControl required>
                    <FormLabel>Potenzial (in m²)</FormLabel>
                    <Input type="number" placeholder="Potenzial (in m²)" {...formField(`${fieldPrefix}.POTENZIAL`)} />
                </FormControl>
            </Box>
            <FormControl required>
                <FormLabel>Dachneigung (in Grad)</FormLabel>
                <Input type="number" placeholder="Dachneigung (in Grad)" {...formField(`${fieldPrefix}.DACHNEIGUNG`)} />
            </FormControl>

            <FormControl required>
                <FormLabel>Traufhöhe (in m)</FormLabel>
                <Input type="number" placeholder="Traufhöhe (in m)" {...formField(`${fieldPrefix}.TRAUFHOEHE`)} />
            </FormControl>
            <FormControl required>
                <FormLabel>Trauflänge (in m)</FormLabel>
                <Input type="number" placeholder="Trauflänge (in m)" {...formField(`${fieldPrefix}.TRAUFLAENGE`)} />
            </FormControl>
            <FormControl>
                <FormLabel>Störflächen</FormLabel>
                <Select
                    multiple
                    sx={{ width: '40rem' }}
                    value={formik.values.roof_type}
                    onChange={handleSelect(formik, `${fieldPrefix}.LIST_STOERF`)}
                >
                    {STOERFLAECHEN.map((stoerflaeche) => (
                        <Option key={stoerflaeche} value={stoerflaeche}>
                            {stoerflaeche}
                        </Option>
                    ))}
                </Select>
            </FormControl>

            <FormControl required>
                <FormLabel>Drohnenaufnahme Dachfläche</FormLabel>
                <Input type="file" {...formFile(formik, `${fieldPrefix}.AUFNAHME`)} />
            </FormControl>

            <FormControl>
                <FormLabel>{t('Possible Module Assignment')}</FormLabel>
                <Input type="file" {...formFile(formik, `${fieldPrefix}.POSSIBLE_MODULE_ASSIGNMENT`)} />
            </FormControl>

            <FormControl>
                <FormLabel>Aufnahme Frontansicht Dachseite</FormLabel>
                <Input type="file" {...formFile(formik, `${fieldPrefix}.AUFNAHME_FRONT`)} />
            </FormControl>
        </>
    );
}
