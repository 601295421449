import { API_BASE_URL, PV_CALCULATOR_API_BASE_URL, QA_PV_CALCULATOR_API_BASE_URL } from 'appConfig';
import { httpClient } from '../../infrastructure/admi-client/http-client';

export interface RoofSegmentDto {
    kwp: number;
    kwh_kwp: number;
    modarea: number;
    roof_pitch: number;
    direction: number;
    building_id: string;
    sum_wb_hu?: number;
}

export interface PVResultDto {
    design_kwp: number;
    design_kwh_per_year_ac: number;
    battery_capacity: number;
    usage: number;
    initial_investment_incl_funding: number;
    initial_investment: number;
    balance_30_years: number;
    rate_of_return: number;
    profit_loss_accumulated: number[];
    amortization_years: number | null;
    co2_savings: number;
    trees: number;
}

export const admiPVCalculatorService = {
    async doPVOptimization(
        roof_segments: RoofSegmentDto[],
        optimizer: string,
        load_profile: string,
        funding_rate: number,
        usage: number | null,
        battery_kwp_factor?: number,
        latitude?: number,
        longitude?: number,
        useQA = false,
        sum_wb_hu?: number,
    ) {
        const response = await httpClient.post<PVResultDto>(
            '/v1/pv-optimization',
            {
                roof_segments,
                optimizer,
                load_profile,
                funding_rate,
                usage,
                battery_kwp_factor,
                latitude,
                longitude,
                sum_wb_hu,
            },
            {
                baseURL: useQA ? QA_PV_CALCULATOR_API_BASE_URL : PV_CALCULATOR_API_BASE_URL,
                headers: {
                    // TODO: This API will in the future be done completely within the backend.
                    // Then, change the API key
                    'X-API_KEY': '9i90tgioufnqwh58sdg8yhajdnrh2ht4',
                },
            },
        );

        return response.data;
    },

    async getRoofSegments(tag: string): Promise<[RoofSegmentDto[], number]> {
        const response = await httpClient.get<any>('/v1/pv-info', {
            params: {
                tag,
            },
            baseURL: API_BASE_URL,
        });

        return [
            response.data.map(
                (row: any) =>
                    ({
                        kwp: (row.kw_17 * 400) / 280 + row.kw_21_7,
                        kwh_kwp: row.kwh_kwp,
                        modarea: row.modarea,
                        roof_pitch: row.neigung,
                        direction: row.richtung,
                        building_id: row.geb_id,
                    }) as RoofSegmentDto,
            ),
            response.data[0]?.sum_wb_hu,
        ];
    },
};
