import html2canvas from 'html2canvas';

export declare type Options = {
    top: number;
    right: number;
    useCORS: boolean;
};

function useScreenshot() {
    return {
        screenshot: async (element: HTMLElement, options: Options) => {
            const cropper = document.createElement('canvas').getContext('2d');

            if (!cropper) {
                throw new Error('Could not create a canvas context');
            }

            return html2canvas(element, { useCORS: options.useCORS }).then((c) => {
                cropper.canvas.width = c.width - options.right;
                cropper.canvas.height = c.height - options.top;

                cropper.drawImage(
                    c,
                    0,
                    options.top,
                    c.width - options.right,
                    c.height - options.top,
                    0,
                    0,
                    c.width - options.right,
                    c.height - options.top,
                );

                return cropper.canvas;
            });
        },
    };
}

export default useScreenshot;
