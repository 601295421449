import { API_BASE_URL } from '../../appConfig';
import axios from 'axios';
import Cookies from 'js-cookie';

const ADMI_BASE_URL = API_BASE_URL || 'http://dev.admi.de:8080';

export const admiRealEstateService = {
    async getPlotImage(uuid: string): Promise<any> {
        uuid = this.formatUuid(uuid);

        const response = await axios.get(`/api/v1/real-estate/plot/${uuid}/image`, {
            baseURL: ADMI_BASE_URL,
            headers: {
                'Authorization': `Bearer ${Cookies.get('CF_Authorization')}`
            },
            responseType: 'arraybuffer',
        });

        return response.data;
    },

    async uploadPlotImage(uuid: string, image: Blob): Promise<any> {
        uuid = this.formatUuid(uuid);

        const formData = new FormData();
        formData.append('image', image, `${uuid}.png`);

        const response = await axios.post(`/api/v1/real-estate/plot/${uuid}/image`, formData, {
            baseURL: ADMI_BASE_URL,
            headers: {
                'Authorization': `Bearer ${Cookies.get('CF_Authorization')}`,
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data;
    },

    formatUuid(uuid: string): string {
        return uuid.replace(/\//g, '-');
    },
};
