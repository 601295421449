import React from 'react';
import { FormControl, FormLabel, Option, Select, Switch, Typography, Input } from '@mui/joy';
import { formFieldBuilder, formFile, handleSelect } from '../../common/formikUtils';
import MultiImageInput from './components/MultImageInput';

export default function FragmentKabelfuehrung({ formik }: any) {
    const formField = (name: string) => formFieldBuilder(formik, name);

    return (
        <>
            <Typography level="h3">Kabelführung</Typography>
            <FormControl required>
                <FormLabel>Kabelführung DC</FormLabel>
                <Select value={formik.values.KABELFUEHRUNG_DC} onChange={handleSelect(formik, 'KABELFUEHRUNG_DC')}>
                    <Option value="außen, Fassadenverlegung">außen, Fassadenverlegung</Option>
                    <Option value="innen, mit Hilfe eines vorhandenen Leerrohrs">
                        innen, mit Hilfe eines vorhandenen Leerrohrs
                    </Option>
                </Select>
            </FormControl>

            <FormControl required>
                <FormLabel>Aufnahme Kabelführung DC innen</FormLabel>
                <MultiImageInput name="AUFNAHME_KABELFUEHRUNG_DC_INNEN" formik={formik} />
            </FormControl>

            <FormControl>
                <FormLabel>Aufnahme Kabelführung DC außen</FormLabel>
                <MultiImageInput name="AUFNAHME_KABELFUEHRUNG_DC_AUSSEN" formik={formik} />
            </FormControl>

            <FormControl>
                <FormLabel>Kabelführung DC außen (in m)</FormLabel>
                <Input
                    type="number"
                    placeholder="Kabelführung DC außen (in m)"
                    {...formField('KABELFUEHRUNG_DC_AUSSEN')}
                />
            </FormControl>

            <FormControl>
                <FormLabel>Aufnahme Hauseintritt</FormLabel>
                <Input type="file" {...formFile(formik, 'AUFNAHME_HAUSEINTRITT')} />
            </FormControl>

            <FormControl required>
                <FormLabel>Anzahl Wanddurchbrüche außen</FormLabel>
                <Input type="number" placeholder="Anzahl Wanddurchbrüche außen" {...formField('ANZAHL_WDB_AUSSEN')} />
            </FormControl>

            <FormControl>
                <FormLabel>Wanddurchbruch überirdisch/unterirdisch</FormLabel>
                <Select value={formik.values.HOEHE_HAUSEINTRITT} onChange={handleSelect(formik, 'HOEHE_HAUSEINTRITT')}>
                    <Option value="überirdisch">überirdisch</Option>
                    <Option value="unterirdisch">unterirdisch</Option>
                </Select>
            </FormControl>

            <FormControl>
                <FormLabel>Typ der Außenwand</FormLabel>
                <Input type="text" placeholder="Typ der Außenwand" {...formField('TYP_AUSSENWAND')} />
            </FormControl>

            <FormControl required>
                <FormLabel>Anzahl Wanddurchbrüche innen</FormLabel>
                <Input type="number" placeholder="Anzahl Wanddurchbrüche innen" {...formField('ANZAHL_WDB_INNEN')} />
            </FormControl>

            <Typography
                component="label"
                endDecorator={
                    <Switch
                        sx={{ ml: 1 }}
                        checked={formik.values.ERDARBEITEN}
                        onChange={(event) => formik.setFieldValue('ERDARBEITEN', event.target.checked)}
                    />
                }
            >
                Erdarbeiten notwendig
            </Typography>
        </>
    );
}
